import React from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'jquery';

import { updateData } from '../data/contentful/actions';

import Layout from '../components/layout';


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state;
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    // const products = this.props.content.data.products || [];
    return (
      <Layout>
        <section class="ds section_404 section_padding_top_75 section_padding_bottom_75">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-md-offset-3 text-center">
                <p class="not_found highlight"> <span>404</span> </p>
                <h1>Oops, page not found!</h1>
                <p> <a href="./" class="theme_button color1 min_width_button">Go to homepage</a> </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
